<template>
  <div>
    <v-bottom-navigation color="info" horizontal dark>
      <v-btn to="/admin/sys_config_collegemanpower">
        <span>ตั้งค่าระบบ </span>
        <v-icon>mdi-wrench</v-icon>
      </v-btn>

      <v-btn to="/admin/personnel_etc">
        <span>อัตรากำลังสถานศึกษา </span>
        <v-icon>mdi-numeric-1-box</v-icon>
      </v-btn>

      <v-btn to="/admin/personnel_etc_tem">
        <span>ข้อมูลลูกจ้างชั่วคราว </span>
        <v-icon>mdi-numeric-2-box</v-icon>
      </v-btn>

      <v-btn to="/admin/power_rate/rate_work_college">
        <span>จำนวนนักเรียน </span>
        <v-icon>mdi-numeric-3-box</v-icon>
      </v-btn>

      <v-btn to="/admin/rate_work_g">
        <span>รายงานอัตรากำลังประจำปี สถานศึกษา</span>
        <v-icon>mdi-numeric-4-box</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  name: 'HRvecManpowerBar',

  data() {
    return {
      

    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>