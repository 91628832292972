<template>
  <div>
    <Manpower_bar/>
    
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="จำนวนนักเรียน จากระบบ ศธ.02"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row no-gutters>
            <v-col cols="12" md="8" class="pa-2">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                filled
                class="mb-2"
              />
              <v-select
                outlined
                :items="years"
                label="เลือกปีข้อมูล"
                v-model="searchYear"
                @change="searchYearSubmit()"               
              >
              </v-select>
              <v-autocomplete
                outlined
                :items="colleges"
                label="ค้นหาวิทยาลัย"
                v-model="collegeSearch"
                @change="collegeSearchSubmit()"
                item-text="college_name"
                item-value="college_code"
              >
              </v-autocomplete>

              <v-card>
                <v-data-table
                  :headers="headerSum"
                  class="elevation-1"
                  :items="rate_work_collegesReport"
                  :loading="loading"
                >
                  <template v-slot:item.TotalAll="{ item }">
                    <div>
                      {{ numberWithCommas(item.TotalAll) }}
                    </div>
                  </template>
                  <template v-slot:item.ch1="{ item }">
                    <div>
                      {{ numberWithCommas(item.ch1) }}
                    </div>
                  </template>
                  <template v-slot:item.ch2="{ item }">
                    <div>
                      {{ numberWithCommas(item.ch2) }}
                    </div>
                  </template>
                  <template v-slot:item.ch3="{ item }">
                    <div>
                      {{ numberWithCommas(item.ch3) }}
                    </div>
                  </template>
                  <template v-slot:item.s1="{ item }">
                    <div>
                      {{ numberWithCommas(item.s1) }}
                    </div>
                  </template>
                  <template v-slot:item.s2="{ item }">
                    <div>
                      {{ numberWithCommas(item.s2) }}
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" class="pa-2">
              <v-btn
                block
                large
                color="primary"
                @click.native="rate_work_collegeCSV()"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>
                <h3>นำเข้าไฟล์รายชื่อ CSV</h3>
              </v-btn>                 
            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="rate_work_colleges"
          class="elevation-1"
          :loading="loading"
          :search="search"
        >
          <template v-slot:item.rate_work_course_id="{ item }">
            <div></div>
          </template>

          <template v-slot:item.rate_work_college_id_class="{ item }">
            <div>
              <span v-if="item.rate_work_college_id_class === '20'">ปวช.</span>
              <span v-else-if="item.rate_work_college_id_class === '30'"
                >ปวส.</span
              >
            </div>
          </template>
          <!-- <template v-slot:item.dels="{ item }">
            <v-icon
              color="red"
              @click.stop="rate_work_collegeDelete(item.id_card)"
            >
              mdi-delete
            </v-icon>
          </template> -->

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
      </base-material-card>

      <!-- V-model csvrate_work_collegedialog -->
      <v-layout>
        <v-dialog
          v-model="csvrate_work_collegedialog"
          persistent
          max-width="50%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="green"
              icon="mdi-content-paste"
              title="นำเข้าข้อมูลไฟล์ CSV"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-card>
                <v-card-text>
                  <v-form ref="csvrate_work_collegedialogform" lazy-validation>
                    <v-container grid-list-md>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-alert
                            border="left"
                            colored-border
                            type="info"
                            elevation="2"
                          >
                            <h2>
                              นำเข้าข้อมูลไฟล์ CSV
                              <input type="file" ref="file2" id="file2" />
                            </h2>
                          </v-alert>
                        </v-col>
                        <v-col cols="12" md="12" class="text-center">
                          <v-btn
                            large
                            color="green"
                            @click.stop="csvrate_work_collegeSubmit()"
                          >
                            <v-icon>mdi-book-plus</v-icon>
                            นำเข้า</v-btn
                          ><v-btn
                            large
                            color="info"
                            to="/admin/rate_work_college_import/"
                            target="_blank"
                          >
                            <v-icon>mdi-exit-to-app</v-icon
                            >ส่งออกไฟล์บันทึกข้อมูล</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="text-right">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  large
                  color="grey"
                  @click.stop="csvrate_work_collegedialog = false"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deleterate_work_collegedialog -->
      <v-layout>
        <v-dialog
          v-model="deleterate_work_collegedialog"
          persistent
          max-width="40%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบข้อมูลผู้ใช้"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form ref="deleterate_work_collegeform" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบข้อมูลผู้ใช้
                          {{ editrate_work_college.college_code }}
                          <span v-if="editrate_work_college.college_name">{{
                            editrate_work_college.college_name
                          }}</span>
                          <div>
                            {{ editrate_work_college.title_s
                            }}{{ editrate_work_college.frist_name }}
                            {{ editrate_work_college.last_name }}
                          </div>
                          <div>
                            {{ editrate_work_college.position_name }} :
                            {{ editrate_work_college.id_position }}
                          </div>
                        </v-flex>
                        <v-text-field
                          filled
                          rounded
                          type="password"
                          label="Password"
                          :rules="[v => v === '0612698358']"
                        >
                        </v-text-field>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="deleterate_work_collegedialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="deleterate_work_collegeSubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import Manpower_bar from '../../../components/admin/manpower_bar.vue';

export default {
  components:{Manpower_bar},
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      valid: true,
      addrate_work_collegedialog: false,
      editrate_work_collegedialog: false,
      deleterate_work_collegedialog: false,
      csvrate_work_collegedialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      rate_work_colleges: [],
      addrate_work_college: {},
      editrate_work_college: {},
      search: "",
      searchYear: "",
      pagination: {},
      headerSum: [
        { text: "ปี", align: "center", value: "rate_work_college_year" },
        { text: "ปวช.1", align: "center", value: "ch1" },
        { text: "ปวช.2", align: "center", value: "ch2" },
        { text: "ปวช.3", align: "center", value: "ch3" },
        { text: "ปวส.1", align: "center", value: "s1" },
        { text: "ปวส.2", align: "center", value: "s2" },
        { text: "รวม", align: "center", value: "TotalAll" }
      ],
      headers: [
        {
          text: "ปีการศึกษา",
          align: "center",
          value: "rate_work_college_year"
        },
        {
          text: "วิทยาลัย",
          align: "left",
          value: "college_name"
        },
        { text: "ประเภทวิชา", align: "center", value: "subject_typeName" },       
        {
          text: "ระดับชั้น",
          align: "center",
          value: "rate_work_college_id_class"
        },
        { text: "สาขาวิชา", align: "center", value: "name_type_course" },
        {
          text: "ปวช.1/ปวส.1",
          align: "center",
          value: "rate_work_field_study_lavel_1"
        },
        {
          text: "ปวช.2/ปวส.2",
          align: "center",
          value: "rate_work_field_study_lavel_2"
        },
        {
          text: "ปวช.3",
          align: "center",
          value: "rate_work_field_study_lavel_3"
        },
       /*  {
          text: "ลบ",
          align: "center",
          value: "dels",
          icon: "mdi-file-document-edit"
        } */
      ],

      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      data_syslog: {},
      upcsv_file: {},
      years: [2565, 2566, 2567, 2568, 2569, 2570],
      rate_work_collegesReport: [],
      collegeSearch:"",
      colleges:[],
    };
  },

  async mounted() {
    let result = await this.$http.post("college.php",{
      ApiKey:this.ApiKey
    })
    this.colleges = result.data
  },

  methods: {
    async searchYearSubmit() {
      this.loading = true;
      let result = await this.$http
        .post("rate_work_college.php", {
          ApiKey: this.ApiKey,
          rate_work_college_year: this.searchYear
        })
        .finally(() => (this.loading = false));
      this.rate_work_colleges = result.data;

      let resultReport = await this.$http.post("rate_work_college.report.php", {
        ApiKey: this.ApiKey,
        rate_work_college_year: this.searchYear
      });
      this.rate_work_collegesReport = resultReport.data;
    
    },
    async collegeSearchSubmit(){
      this.loading = true;
      let result = await this.$http
        .post("rate_work_college.php", {
          ApiKey: this.ApiKey,
          rate_work_college_code: this.collegeSearch
        })
        .finally(() => (this.loading = false));
      this.rate_work_colleges = result.data;

      let resultReport = await this.$http.post("rate_work_college.report.php", {
        ApiKey: this.ApiKey,
        rate_work_college_code: this.collegeSearch
      });
      this.rate_work_collegesReport = resultReport.data;
  
    },

    async rate_work_collegeCSV() {
      this.csvrate_work_collegedialog = true;
    },

    async csvrate_work_collegeSubmit() {
      let result = "";
      let uploaded = null;
      if (this.$refs.file2.files[0]) {
        let formData = new FormData();
        let filename = this.time_stamp + ".rate_work_college.csv";
        formData.append("file", this.$refs.file2.files[0]);
        formData.append("filename", "../HRvecfiles/" + filename);
        formData.append("ApiKey", this.ApiKey);
        result = await this.$http.post("rate_work_collegecsv.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        uploaded = true;
      } else {
        uploaded = false;
      }
      if (result.data.status == true) {
        this.rate_work_college = result.data;
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.rate_work_collegeQueryAll();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
        this.rate_work_collegeQueryAll();
      }
      this.csvrate_work_collegedialog = false;
    },

    numberWithCommas(x) {
      x = x.toString();
      var pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
      return x;
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },

    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>
